import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap'
import './NavBar.css'

export default function NavBar(){
    return(
        <>
        <Navbar className='navbar'>
            <Container>
            <Nav>
                <Nav.Link className='navbar-link' style={{marginLeft: "600px"}} href='/add-staff'>Add Staff</Nav.Link>
                <Nav.Link className='navbar-link' href='/add-patient'>Add Patient</Nav.Link>
                <Nav.Link className='navbar-link' href='/add-appointment'>Add Appointment</Nav.Link>
                <Nav.Link className='navbar-link' href='/add-checkup'>Add Checkup</Nav.Link>
                <Nav.Link className='navbar-link' style={{backgroundColor: "lightblue", borderRadius: "10px", marginLeft: "25px", color: "#006064"}} href='/'>Signout</Nav.Link>
            </Nav>
            </Container>
        </Navbar>
        </>
    )
}