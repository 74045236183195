import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Table, Toast, ToastContainer } from 'react-bootstrap';
import Pagination from '../../Components/Features/Pagination/Pagination';
import SearchBar from '../../Components/Features/SearchBar/SearchBar';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function ViewAllAppointments() {
  const [appointments, setAppointments] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(5);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('');

  useEffect(() => {
    axios.get('https://project01-n0pn.onrender.com/api/appointment/getallappointments').then((response) => {
      setAppointments(response.data);
      setFilteredAppointments(response.data);  // Initialize filteredAppointments with all appointments
    });
  }, []);

  // Calculate the number of pages
  const totalPages = Math.ceil(filteredAppointments.length / appointmentsPerPage);

  // Get current appointments data for the page
  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = filteredAppointments.slice(indexOfFirstAppointment, indexOfLastAppointment);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDelete = (appointmentId) => {
    axios.delete(`https://project01-n0pn.onrender.com/api/appointment/deleteappointment/${appointmentId}`).then((response) => {
      setToastMessage("Appointment deleted successfully");
      setToastVariant('danger');
      setShowToast(true);
      setAppointments(appointments.filter(appointment => appointment.appointment_id !== appointmentId));
      setFilteredAppointments(filteredAppointments.filter(appointment => appointment.appointment_id !== appointmentId));
    }).catch((error) => {
      setToastMessage('Error deleting appointment');
      setToastVariant('danger');
      setShowToast(true);
      console.error('Error deleting appointment:', error);
    });
  };

  const handleStatusToggle = (appointmentId, currentStatus) => {
    const newStatus = currentStatus === 'Accepted' ? 'Cancelled' : 'Accepted';
    axios.put(`https://project01-n0pn.onrender.com/api/appointment/updateappointment/${appointmentId}`, { appointment_status: newStatus })
      .then(() => {
        setToastMessage(`Appointment status changed to ${newStatus}`);
        setToastVariant('primary');
        setShowToast(true);
        // Update status directly in local state
        const updatedAppointments = appointments.map(appointment => {
          if (appointment.appointment_id === appointmentId) {
            return { ...appointment, appointment_status: newStatus };
          }
          return appointment;
        });
        setAppointments(updatedAppointments);
        setFilteredAppointments(updatedAppointments);
      })
      .catch((error) => {
        setToastMessage('Error updating status');
        setToastVariant('primary');
        setShowToast(true);
        console.error('Error updating status:', error);
      });
  };

  const handleSearch = (query) => {
    setCurrentPage(1);  // Reset to first page on new search
    if (query) {
      const lowercasedQuery = query.toLowerCase();
      const filteredData = appointments.filter(appointment =>
        Object.values(appointment).some(value =>
          value.toString().toLowerCase().includes(lowercasedQuery)
        )
      );
      setFilteredAppointments(filteredData);
    } else {
      setFilteredAppointments(appointments);
    }
  };

  return (
    <>
      <Card className='card-container'>
        <h1 className="header">Appointments Management</h1>
        <Button className="report-button" as={Link} to={'/report-appointment'}>Generate Report</Button>
        <ToastContainer position="top-end" className="p-3">
          <Toast onClose={() => setShowToast(false)} show={showToast} className='toast' bg={toastVariant} delay={3000} autohide>
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </ToastContainer>
        <SearchBar onSearch={handleSearch} />
        <Table responsive className="table">
          <thead>
            <tr>
              <th>Patient NIC</th>
              <th>Doctor Name</th>
              <th>Appointment Date</th>
              <th>Created At</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentAppointments.map((appointment) => (
              <tr key={appointment.appointment_id}>
                <td>{appointment.patient_nic}</td>
                <td>{appointment.doctor_name}</td>
                <td>{moment(appointment.appointment_date).format("MMMM Do YYYY, h:mm:ss a")}</td>
                <td>{moment(appointment.createdAtAppointment).format("MMMM Do YYYY, h:mm:ss a")}</td>
                <td>{appointment.appointment_status}</td>
                <td>
                  <Button variant="primary" onClick={() => handleStatusToggle(appointment.appointment_id, appointment.appointment_status)}>
                    {appointment.appointment_status === 'Accepted' ? 'Cancelled' : 'Accepted'}
                  </Button> {" "}
                  <Button variant="secondary" as={Link} to={`/view-appointment/${appointment.appointment_id}`}>View</Button> {" "}
                  <Button variant="warning" as={Link} to={`/update-appointment/${appointment.appointment_id}`}>Update</Button> {" "}
                  <Button variant="danger" onClick={() => handleDelete(appointment.appointment_id)}>Delete</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </Card>
    </>
  );
}