import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, FormLabel, FormControl, Container, Card, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

export default function UpdateCheckup() {
  const [checkup, setCheckup] = useState(null);
  const [loading, setLoading] = useState(true);
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const { checkupId } = useParams();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate();
  const [toastVariant, setToastVariant] = useState('');

  useEffect(() => {
    // Fetch the appointment details
    axios.get(`https://project01-n0pn.onrender.com/api/checkup/getcheckup/${checkupId}`)
      .then((response) => {
        setCheckup(response.data);
        setLoading(false);
      })
      .catch((error) => {
        alert('Error loading appointment.');
        setLoading(false);
      });

    // Fetch patients
    axios.get('https://project01-n0pn.onrender.com/api/patient/getallpatients')
      .then((response) => {
        setPatients(response.data);
      })
      .catch((error) => {
        console.error('Error fetching patients:', error);
      });

    // Fetch doctors
    axios.get('https://project01-n0pn.onrender.com/api/staff/getallusers')
      .then((response) => {
        const doctorData = response.data.filter(user => user.user_role === 'Doctor');
      setDoctors(doctorData);
      })
      .catch((error) => {
        console.error('Error fetching doctors:', error);
      });
  }, [checkupId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckup((prevAppointment) => ({
      ...prevAppointment,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.put(`https://project01-n0pn.onrender.com/api/checkup/updatecheckup/${checkupId}`, checkup)
      .then((response) => {
        setToastMessage('Checkup updated successfully!');
        setToastVariant('warning');
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false); // Hide the toast after 3 seconds
          navigate('/view-all-checkups');
        }, 3000);
      })
      .catch((error) => {
        setToastMessage('Error updating appointment.');
        setToastVariant('warning');
        setShowToast(true);
        console.error('Error updating appointment:', error);
      });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!checkup) {
    return <p>No data found for this checkup.</p>;
  }

  return (
    <Container>
      <Card className='card-container'>
        <h1 className='header'>Update Appointment</h1>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup controlId="formPatientId">
                <FormLabel>Patient NIC</FormLabel>
                <FormControl
                  as="select"
                  name="patient_id"
                  value={checkup.patient_id}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select patient NIC</option>
                  {patients.map(patient => (
                    <option key={patient.patient_id} value={patient.patient_nic}>
                      {patient.patient_nic}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formDoctorName">
                <FormLabel>Doctor Name</FormLabel>
                <FormControl
                  as="select"
                  name="doctor_user_name"
                  value={checkup.doctor_user_name}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select doctor name</option>
                  {doctors.map(doctor => (
                    <option key={doctor.user_id} value={`${doctor.first_name} ${doctor.last_name}`}>
                    {`${doctor.first_name} ${doctor.last_name}`}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup controlId="formPatientDesc">
                <FormLabel>Patient Description</FormLabel>
                <FormControl
                  type="text"
                  as="textarea"
                  name="patient_desc"
                  value={checkup.patient_desc}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup controlId="formCheckupNote">
                <FormLabel>Checkup Note</FormLabel>
                <FormControl
                  type="text"
                  as="textarea"
                  name="checkup_note"
                  value={checkup.checkup_note}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup controlId="formMedicineNote">
                <FormLabel>Suggest Medicines</FormLabel>
                <FormControl
                  type="text"
                  as="textarea"
                  name="medicine_note"
                  value={checkup.medicine_note}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button className="update-button" variant="warning" type="submit">
            Update Checkup
          </Button>
        </Form>
      </Card>
      <ToastContainer position="top-end" className="p-3" style={{marginTop:"35px"}}>
        <Toast onClose={() => setShowToast(false)} show={showToast} className='toast' bg={toastVariant} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
}