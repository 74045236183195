import axios from "axios";
import { useEffect, useState } from "react"
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export default function ViewPatient(){
    const [patient, setPatient] = useState([]);
    const {patientId} = useParams();

    useEffect(() => {
        axios.get(`https://project01-n0pn.onrender.com/api/patient/getpatient/${patientId}`).then((response) => {
            setPatient(response.data);
        })
})

    return(
        <>
        <Card className="card-container">
        <h1 className="header">View Patient</h1>
        <Row>
        <Col>
        <CardHeader>
            <CardTitle>Name</CardTitle>
            <CardText>{patient.name}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>NIC</CardTitle> 
            <CardText>{patient.patient_nic}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Date of birth</CardTitle>
        <CardText>{patient.patient_dob}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Gender</CardTitle>
        <CardText>{patient.gender}</CardText>
        </CardHeader>
        <br/>
        </Col>
        <Col>
        <CardHeader>
        <CardTitle>Age</CardTitle> 
        <CardText>{patient.age}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Contact Number</CardTitle>
        <CardText>{patient.contact_number}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Email</CardTitle>
        <CardText>{patient.email}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Address</CardTitle>
        <CardText>{patient.address}</CardText>
        </CardHeader>
        </Col>
        <Col>
        <img src={patient.imageUrl} className="user-image" alt="patient"/>
        </Col>
        </Row>
        </Card>
        </>
    )
}