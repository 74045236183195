import axios from "axios";
import { useEffect, useState } from "react"
import { Card, CardHeader, CardText, CardTitle, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";

export default function ViewCheckup(){
    const [checkup, setCheckup] = useState([]);
    const {checkupId} = useParams();

    useEffect(() => {
        axios.get(`https://project01-n0pn.onrender.com/api/checkup/getcheckup/${checkupId}`).then((response) => {
            setCheckup(response.data);
        })
})

    return(
        <>
        <Card className="card-container">
        <h1 className="header">View Checkup</h1>
        <Row>
        <Col>
        <CardHeader>
            <CardTitle>Doctor Username</CardTitle>
            <CardText>{checkup.doctor_user_name}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Patient NIC</CardTitle> 
            <CardText>{checkup.patinet_nic}</CardText>
            </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Patient Description</CardTitle>
        <CardText>{checkup.patient_desc}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Checkup Note</CardTitle>
        <CardText>{checkup.checkup_note}</CardText>
        </CardHeader>
        <br/>
        <CardHeader>
        <CardTitle>Suggest Medicines</CardTitle>
        <CardText>{checkup.medicine_note}</CardText>
        </CardHeader>
        <br/>
        </Col>
        </Row>
        </Card>
        </>
    )
}