import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, FormGroup, FormLabel, FormControl, Container, Card, Row, Col, Toast, ToastContainer } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function AddCheckup() {
  const [checkup, setCheckup] = useState({
    patient_nic: '',
    doctor_user_name: '',
    patient_desc: '',
    checkup_note: '',
    medicine_note: ''
  });
  const [patients, setPatients] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate();
  const [toastVariant, setToastVariant] = useState('');

  useEffect(() => {
    // Fetch patients and doctors when the component mounts
    axios.get('https://project01-n0pn.onrender.com/api/patient/getallpatients')
      .then((response) => {
        setPatients(response.data);
      })
      .catch((error) => {
        console.error('Error fetching patients:', error);
      });

    axios.get('https://project01-n0pn.onrender.com/api/staff/getallusers')
      .then((response) => {
        const doctorData = response.data.filter(user => user.user_role === 'Doctor');
      setDoctors(doctorData);
      })
      .catch((error) => {
        console.error('Error fetching doctors:', error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckup((prevAppointment) => ({
      ...prevAppointment,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post('https://project01-n0pn.onrender.com/api/checkup/savecheckup', checkup)
      .then((response) => {
        setToastMessage('Checkup added successfully!');
        setToastVariant('success');
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false); // Hide the toast after 3 seconds
          navigate("/home");
        }, 3000);
      })
      .catch((error) => {
        setToastMessage('Error adding checkup.');
        setToastVariant('success');
        setShowToast(true);
        console.error('Error adding checkup:', error);
      });
  };

  return (
    <Container>
      <Card className='card-container'>
        <h1 className='header'>Add New Checkup</h1>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormGroup controlId="formPatientId">
                <FormLabel>Patient NIC</FormLabel>
                <FormControl
                  as="select"
                  name="patient_nic"
                  value={checkup.patient_nic}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select patient NIC</option>
                  {patients.map(patient => (
                    <option key={patient.patient_id} value={patient.patient_nic}>
                      {patient.patient_nic}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup controlId="formDoctorName">
                <FormLabel>Doctor Name</FormLabel>
                <FormControl
                  as="select"
                  name="doctor_user_name"
                  value={checkup.doctor_user_name}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select doctor username</option>
                  {doctors.map(doctor => (
                    <option key={doctor.user_id} value={`${doctor.first_name} ${doctor.last_name}`}>
                    {`${doctor.first_name} ${doctor.last_name}`}
                    </option>
                  ))}
                </FormControl>
              </FormGroup>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <FormGroup controlId="formPatientDesc">
                <FormLabel>Patient Description</FormLabel>
                <FormControl
                  type="text"
                  as="textarea"
                  name="patient_desc"
                  value={checkup.patient_desc}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <FormGroup controlId="formCheckupNote">
                <FormLabel>Checkup Note</FormLabel>
                <FormControl
                  type="text"
                  as="textarea"
                  name="checkup_note"
                  value={checkup.checkup_note}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col>
              <FormGroup controlId="formMedicineNote">
                <FormLabel>Suggest Medicines</FormLabel>
                <FormControl
                  type="text"
                  as="textarea"
                  name="medicine_note"
                  value={checkup.medicine_note}
                  onChange={handleChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <br/>
          <Button className="submit-button" variant="success" type="submit">
            Add Checkup
          </Button>
        </Form>
      </Card>
      <ToastContainer position="top-end" className="p-3" style={{marginTop:"35px"}}>
        <Toast onClose={() => setShowToast(false)} show={showToast} className='toast' bg={toastVariant} delay={3000} autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </Container>
  );
}